/** @jsxImportSource @emotion/react */
import tw, { styled } from "twin.macro";

import { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { Button, NumberField } from "@clevero/components";
import get from "lodash/get";
import { IoIosArrowForward } from "react-icons/io";
import { useSearchParams } from "react-router-dom";
import { usePagination, useTable } from "react-table";
import { FaStepBackward, FaStepForward } from "react-icons/fa";

import { useGetToursTable } from "../api/tours.api";
import createMarkup from "../Utility/parseHTML";
import useHeightTrim from "../Utility/useHeightTrim";

import { AvailabilityStatus, EnquiryMessage } from "./Components.styled";
import EventDetails from "./Calendar/EventDetails";
import Loader from "./Loader";
import Modal from "./Modal";
import { formatInTimeZone } from "date-fns-tz";
import { TIMEZONE } from "../constants";

const INITIAL_PAGINZATION_STATE = {
    queryPageIndex: 0,
    queryPageSize: 10,
    totalCount: null,
};

const PAGINATION_ACTIONS = {
    PAGE_CHANGED: "PAGE_CHANGED",
    PAGE_SIZE_CHANGED: "PAGE_SIZE_CHANGED",
    TOTAL_COUNT_CHANGED: "TOTAL_COUNT_CHANGED",
};

const paginationReducer = (state, { type, payload }) => {
    switch (type) {
        case PAGINATION_ACTIONS.PAGE_CHANGED:
            return {
                ...state,
                queryPageIndex: payload,
            };
        case PAGINATION_ACTIONS.PAGE_SIZE_CHANGED:
            return {
                ...state,
                queryPageSize: payload,
            };
        case PAGINATION_ACTIONS.TOTAL_COUNT_CHANGED:
            return {
                ...state,
                totalCount: payload,
            };
        default:
            throw new Error(`Unhandled action type: ${type}`);
    }
};

// const PAGE_SIZE_OPTIONS = [
//     { label: "10", value: 10 },
//     { label: "20", value: 20 },
//     { label: "30", value: 30 },
//     { label: "40", value: 40 },
//     { label: "50", value: 50 },
// ];

const isEven = idx => idx % 2 !== 0;

const CenterDiv = tw.div`mt-20 flex items-center justify-center`;
const TableWrapper = tw.div`relative overflow-y-scroll`;
const Table = tw.table`table-auto text-sm text-center w-full`;
const Thead = tw.thead`bg-[#F6FBF3] uppercase sticky top-0 shadow`;
const Tbody = tw.tbody`text-gray-600 overflow-y-hidden`;
const Tr = styled.tr(({ even }) => [even && tw`bg-[#E0F1D7]`]);
const Th = tw.th`font-semibold py-4 px-2`;
const Td = tw.td`font-light py-4 px-2`;

const RichTextDisplay = ({ value }) => {
    const {
        trimContainerRef,
        shouldTrim: shouldTrimTarget,
        isTrimmed: isTrimmedTarget,
        toggleTrim: toggleTrimTarget,
        trimmedHeight: trimmedHeightTarget,
    } = useHeightTrim({ effectHeight: 200, trimmedHeight: 120 });

    const toggleShowMoreTarget = e => {
        e.stopPropagation();
        toggleTrimTarget();
    };
    return (
        <div>
            <div
                ref={trimContainerRef}
                tw="text-left overflow-ellipsis overflow-hidden"
                style={shouldTrimTarget && isTrimmedTarget ? { height: trimmedHeightTarget, overflow: "hidden" } : {}}
            >
                <div dangerouslySetInnerHTML={createMarkup(value)} />
            </div>
            {shouldTrimTarget && (
                <div onClick={toggleShowMoreTarget} tw="font-semibold text-left cursor-pointer mt-1">
                    {isTrimmedTarget ? "...Show more" : "Show less"}
                </div>
            )}
        </div>
    );
};

const ActionButton = ({ children, ...rest }) => (
    <Button {...rest} tw="font-normal uppercase text-base">
        {children}
    </Button>
);

const EnquiryModal = ({ isBookingFinalised }) => {
    const ModalRef = useRef(null);
    return (
        <>
            <ActionButton color="#000080" onClick={() => ModalRef.current.open()}>
                Enquire
                <IoIosArrowForward size={"1.25rem"} />
            </ActionButton>
            <Modal ref={ModalRef}>
                <div tw="text-center bg-white rounded-md border-2 border-gray-600 p-4 pt-6">
                    <EnquiryMessage isBookingFinalised={isBookingFinalised} />
                </div>
            </Modal>
        </>
    );
};

const EventTable2 = () => {
    const [searchParams] = useSearchParams();
    const url = searchParams.get("url");

    const ModalRef = useRef(null);
    const [selectedTour, setSelectedTour] = useState(null);

    const selectedTourData = useMemo(
        () => ({
            productName: get(selectedTour, ["product", "product-name"], null),
            description: get(selectedTour, ["product", "tour-summary"], "").replace(/(<([^>]+)>)/gi, ""),
            tag: get(selectedTour, ["availability-status", "tag"], null),
            colorCode: get(selectedTour, ["availability-status", "colorCode"], null),
            sortedPrice: get(selectedTour, ["priceTypes"], []).sort((priceTypeA, priceTypeB) => {
                const priceTypeAKey = priceTypeA.value
                    .toLowerCase()
                    .replace(" price", "")
                    .concat(" price")
                    .replaceAll(" ", "-");
                const priceTypeBKey = priceTypeB.value
                    .toLowerCase()
                    .replace(" price", "")
                    .concat(" price")
                    .replaceAll(" ", "-");
                return +get(selectedTour, [priceTypeAKey], 0) - +get(selectedTour, [priceTypeBKey], 0);
            }),
            startDate: get(selectedTour, ["start-date"], null),
            endDate: get(selectedTour, ["end-date"], null),
            duration: get(selectedTour, ["product", "number-of-days"], null),
            variedItineraryDetails: get(selectedTour, ["varied-itinerary-details"], null),
            variedItinerary: get(selectedTour, ["varied-itinerary"], false),
            modalImageHtml: get(selectedTour, ["product", "pre-departure-tour-photos"], "").replaceAll("\\", ""),
            status: get(selectedTour, ["status", "0", "id"]),
        }),
        [selectedTour]
    );

    const isBookingFinalised = +selectedTourData.status === 149249;
    const isEnquirable = selectedTourData.tag && selectedTourData.tag.toLowerCase() === "enquire";

    const [{ queryPageIndex, queryPageSize, totalCount }, dispatch] = useReducer(
        paginationReducer,
        INITIAL_PAGINZATION_STATE
    );

    const {
        data: toursData = {},
        isLoading: isLoadingTours,
        error: isErrorLoadingTours,
        isSuccess: isSuccessLoadingTours,
        isPreviousData: isPreviousToursData,
    } = useGetToursTable({
        params: { url, pageIndex: queryPageIndex + 1, pageSize: queryPageSize },
        enabled: !!url,
    });

    const tableColumns = useMemo(
        () => [
            {
                Header: "Departures",
                accessor: "start-date",
                Cell: cell =>
                    cell.value ? (
                        <time>
                            <b>{formatInTimeZone(new Date(cell.value), TIMEZONE, "d MMM yyyy")}</b>
                        </time>
                    ) : (
                        "-"
                    ),
            },
            {
                Header: "Availability",
                accessor: "availability-status",
                Cell: cell =>
                    cell.value ? (
                        <AvailabilityStatus color={cell.value.colorCode} renderComponent={!!cell.value.tag}>
                            {cell.value.tag}
                        </AvailabilityStatus>
                    ) : (
                        "-"
                    ),
            },
            {
                Header: "Sale price (AUD)",
                id: "sale-price",
                Cell: ({ row }) => {
                    const [leastPrice] = get(row.original, ["priceTypes"], []).sort((priceTypeA, priceTypeB) => {
                        const priceTypeAKey = priceTypeA.value
                            .toLowerCase()
                            .replace(" price", "")
                            .concat(" price")
                            .replaceAll(" ", "-");
                        const priceTypeBKey = priceTypeB.value
                            .toLowerCase()
                            .replace(" price", "")
                            .concat(" price")
                            .replaceAll(" ", "-");
                        return +get(row.original, [priceTypeAKey], 0) - +get(row.original, [priceTypeBKey], 0);
                    });
                    if (leastPrice) {
                        const lowestPriceKey = leastPrice.value
                            .toLowerCase()
                            .replace(" price", "")
                            .concat(" price")
                            .replaceAll(" ", "-");
                        const lowestValue = row.original[lowestPriceKey];
                        return (
                            <div style={{ position: "relative" }}>
                                <span style={{ whiteSpace: "nowrap" }}>
                                    <b>${lowestValue}</b>
                                </span>
                            </div>
                        );
                    } else {
                        return "-";
                    }
                },
            },
            {
                Header: "Normal price (AUD)",
                accessor: "normal-price",
                Cell: cell =>
                    (
                        <span style={{ whiteSpace: "nowrap" }}>
                            <b>${cell.value}</b>
                        </span>
                    ) || "-",
            },
            {
                Header: "Tour Variation Details",
                accessor: "varied-itinerary-details",
                Cell: cell => (cell.value ? <RichTextDisplay value={cell.value} /> : "-"),
            },
            {
                Header: () => null,
                id: "action",
                Cell: ({ row }) => {
                    const status = row?.original?.status?.[0]?.id;
                    const isBookingFinalised = +status === 149249;
                    const availability = row.original["availability-status"];
                    if (availability) {
                        const isEnquirable = availability.tag.toLowerCase() === "enquire";
                        const hasSoldOut = availability.tag.toLowerCase() === "sold out";

                        return isEnquirable ? (
                            <EnquiryModal isBookingFinalised={isBookingFinalised} />
                        ) : hasSoldOut ? (
                            <ActionButton color={availability.colorCode} disabled>
                                Sold out
                            </ActionButton>
                        ) : (
                            <ActionButton
                                color={availability.colorCode}
                                onClick={() => {
                                    setSelectedTour(row.original);
                                    ModalRef.current.open();
                                }}
                            >
                                Book Now
                            </ActionButton>
                        );
                    } else {
                        return "-";
                    }
                },
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        // pageCount,
        gotoPage,
        nextPage,
        previousPage,
        // setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns: tableColumns,
            data: isSuccessLoadingTours ? toursData.tours : [],
            initialState: { pageIndex: queryPageIndex, pageSize: queryPageSize },
            manualPagination: true,
            pageCount: isSuccessLoadingTours ? Math.ceil(totalCount / queryPageSize) : null,
        },
        usePagination
    );

    useEffect(() => {
        dispatch({ type: PAGINATION_ACTIONS.PAGE_CHANGED, payload: pageIndex });
    }, [pageIndex]);

    useEffect(() => {
        dispatch({ type: PAGINATION_ACTIONS.PAGE_SIZE_CHANGED, payload: pageSize });
        gotoPage(0);
    }, [pageSize, gotoPage]);

    useEffect(() => {
        if (toursData.totalTours) {
            dispatch({
                type: PAGINATION_ACTIONS.TOTAL_COUNT_CHANGED,
                payload: toursData.totalTours,
            });
        }
    }, [toursData.totalTours]);

    if (isErrorLoadingTours) {
        return <p>Error</p>;
    }

    if (isLoadingTours && !isPreviousToursData) {
        return (
            <CenterDiv>
                <Loader />
            </CenterDiv>
        );
    }

    return (
        <div>
            <TableWrapper>
                {isSuccessLoadingTours ? (
                    <div tw="relative">
                        {isPreviousToursData && (
                            <div tw="absolute backdrop-blur-sm inset-0 z-10">
                                <div tw="absolute flex inset-0 justify-center items-center">
                                    <Loader />
                                </div>
                                <div tw="h-1 inset-x-0 top-4 animate-pulse bg-LAA-primary-highlight z-50" />
                            </div>
                        )}

                        <Table {...getTableProps()}>
                            <Thead>
                                {headerGroups.map(headerGroup => (
                                    <Tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <Th {...column.getHeaderProps()}>{column.render("Header")}</Th>
                                        ))}
                                    </Tr>
                                ))}
                            </Thead>

                            <Tbody {...getTableBodyProps()}>
                                {page.map((row, i) => {
                                    prepareRow(row);
                                    return (
                                        <Tr {...row.getRowProps()} even={isEven(i)}>
                                            {row.cells.map(cell => {
                                                return <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>;
                                            })}
                                        </Tr>
                                    );
                                })}

                                {page.length === 0 &&
                                    headerGroups.map(headerGroup => (
                                        <Tr {...headerGroup.getHeaderGroupProps()}>
                                            <Td colSpan={headerGroup.headers.length}>
                                                <div tw="text-sm text-center text-gray-400">
                                                    -- No events available --
                                                </div>
                                            </Td>
                                        </Tr>
                                    ))}
                            </Tbody>
                        </Table>
                        <div tw="flex my-2 text-base text-[#9da0a8] p-2 bg-white w-full justify-between items-baseline">
                            <div tw="flex gap-2 items-baseline">
                                Go to page:
                                <NumberField
                                    tw="w-20"
                                    value={pageIndex + 1}
                                    onChange={e => {
                                        const page = e.value ? Number(e.value) - 1 : 0;
                                        gotoPage(page);
                                    }}
                                    hideControls
                                />
                            </div>
                            <div tw="flex  items-center leading-none gap-1">
                                {/* <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                    <FaFastBackward />
                                </button> */}
                                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                                    <FaStepBackward tw="text-LAA-primary-dim" />
                                </button>
                                <span>
                                    Page{" "}
                                    <strong>
                                        {pageIndex + 1} of {pageOptions.length}
                                    </strong>{" "}
                                </span>
                                <button onClick={() => nextPage()} disabled={!canNextPage}>
                                    <FaStepForward tw="text-LAA-primary-dim" />
                                </button>
                                {/* <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                    <FaFastForward />
                                </button> */}
                            </div>
                            {/* <div tw="flex gap-2 items-baseline">
                                <Label label="Page size:" />
                                <DropdownField
                                    tw="w-20"
                                    value={pageSize}
                                    onChange={e => {
                                        const value = Number(e.value);
                                        if (PAGE_SIZE_OPTIONS.some(option => option.value === value)) {
                                            setPageSize(value);
                                        }
                                    }}
                                    options={PAGE_SIZE_OPTIONS}
                                    isClearable={false}
                                />
                            </div> */}
                        </div>
                    </div>
                ) : null}
            </TableWrapper>
            <Modal ref={ModalRef}>
                <EventDetails
                    isEnquirable={isEnquirable}
                    isBookingFinalised={isBookingFinalised}
                    data={selectedTourData}
                    eventDetails={selectedTour}
                />
            </Modal>
        </div>
    );
};

export default EventTable2;
