import { useEffect, useReducer, useRef, useState } from "react";

const useHeightTrim = ({ effectHeight, trimmedHeight }) => {
    const trimContainerRef = useRef(null);
    const [shouldTrim, setShouldTrim] = useState(false);
    const [isTrimmed, toggleTrim] = useReducer(state => !state, true);

    useEffect(() => {
        if (trimContainerRef && trimContainerRef.current) {
            const errorMargin = 0;
            const hasVerticalOverflow = effectHeight < trimContainerRef.current.scrollHeight - errorMargin;
            setShouldTrim(hasVerticalOverflow);
        }
    }, [effectHeight]);

    return {
        trimContainerRef,
        shouldTrim,
        isTrimmed,
        toggleTrim,
        trimmedHeight,
    };
};

export default useHeightTrim;
