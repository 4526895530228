/** @jsxImportSource @emotion/react */
import tw, { styled } from "twin.macro";
import { useState } from "react";
import { formatInTimeZone } from "date-fns-tz";

import { Button } from "@clevero/components";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { GrNext } from "react-icons/gr";
import { FaCalendarAlt } from "react-icons/fa";
import { TIMEZONE } from "../constants";

const StyledStatus = styled.span(({ color, bookingHeader, alignSub }) => [
    tw`min-w-min bg-yellow-600 rounded-xl px-2 py-1 text-white font-semibold whitespace-nowrap`,
    (color && `background-color: ${color}`) || tw`bg-LAA-primary-dim`,
    bookingHeader && tw`text-sm`,
    alignSub && `vertical-align: super`,
]);

export const AvailabilityStatus = ({ children, renderComponent, ...rest }) =>
    renderComponent && <StyledStatus {...rest}>{children}</StyledStatus>;

export const ContinueButton = props => (
    <Button {...props} color="#62BA38">
        CONTINUE
        <IoIosArrowForward size={"1.25rem"} />
    </Button>
);

// const LinkTag = tw.a`flex justify-between align-middle text-decoration[none]`;
const PopUp = tw.div`absolute z-50 text-center bg-white shadow-lg rounded-md border-2 border-gray-600 p-4 max-w-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2`;
export const EnquiryMessage = ({ isBookingFinalised }) => (
    <>
        {isBookingFinalised ? (
            <div>
                <div tw="font-semibold text-xl uppercase">Tour on request</div>
                <p tw="font-semibold p-5 pt-2">
                    As this departure is leaving shortly and remaining rooms may have been handed back to our suppliers,
                    any further bookings are on a request basis. Please contact our Reservations Team on (02) 9975 4553
                    or email: walks@lifesanadventure.com.au to request a place on this tour.
                </p>
            </div>
        ) : (
            <div>
                <p tw="font-semibold p-5">
                    If interested in this date, please contact our Reservations Team on (02) 9975 4553 or by email at
                    walks@lifesanadventure.com.au to discuss booking on this date.
                </p>
            </div>
        )}
    </>
);
export const EnquiryButton = props => {
    const [showPopUp, setShowPopUp] = useState(false);
    return (
        <>
            {showPopUp && (
                <PopUp>
                    <div>
                        <button tw="block ml-auto" onClick={() => setShowPopUp(prev => !prev)}>
                            <IoClose size="1.5rem" />
                        </button>
                    </div>

                    {props.isBookingFinalised ? (
                        <div>
                            <div tw="font-semibold text-xl uppercase">Tour on request</div>
                            <p tw="font-semibold p-5 pt-2">
                                As this departure is leaving shortly and remaining rooms may have been handed back to
                                our suppliers, any further bookings are on a request basis. Please contact our
                                Reservations Team on (02) 9975 4553 or email: walks@lifesanadventure.com.au to request a
                                place on this tour.
                            </p>
                        </div>
                    ) : (
                        <div>
                            <p tw="font-semibold p-5">
                                If interested in this date, please contact our Reservations Team on (02) 9975 4553 or by
                                email at walks@lifesanadventure.com.au to discuss booking on this date.
                            </p>
                        </div>
                    )}
                </PopUp>
            )}
            <Button {...props} color="#000080" onClick={() => setShowPopUp(prev => !prev)}>
                {/* <LinkTag
                    target="__blank"
                    href="https://forms.clevero.co/9ac88bc3-09f8-40ed-a42a-23e05ba1fac0/4fc42211-92ff-4396-8437-53db5668c1bd"
                > */}
                Enquire
                <IoIosArrowForward size={"1.25rem"} />
                {/* </LinkTag> */}
            </Button>
        </>
    );
};

export const BackButton = props => (
    <Button {...props} color="#62BA38" variant="outline">
        <IoIosArrowBack size={"1.25rem"} />
        BACK
    </Button>
);

export const FieldWrapper = styled.span(({ placeCenter, alignEnd, spaceBetween }) => [
    tw`flex flex-col gap-1`,
    placeCenter && tw`self-center`,
    alignEnd && tw`items-end`,
    spaceBetween && tw`justify-between flex-row mt-1`,
]);

export const FieldGroup = tw.div`grid gap-6 grid-cols-1 sm:grid-cols-2`;

const ShadowBox = tw.div`w-full min-w-max flex items-center justify-around rounded-lg px-5 py-3 m-auto mt-8 mb-4 shadow-[0px 0px 14.336538314819336px 0px #00000014]`;
const ItemWrapper = tw.div`flex items-center gap-4`;
export const FontWeight = styled.span(
    ({ weightBold, isStartEnd, boldBlock, semibold, isLightGray, isRed, isGreen }) => [
        tw`font-normal text-sm`,
        weightBold && tw`font-semibold text-gray-700`,
        isStartEnd && tw`font-semibold text-[13px] pl-[1rem] mt-[1.6rem]`,
        boldBlock && tw`font-semibold text-sm block text-gray-700`,
        semibold && tw`font-semibold text-sm text-gray-500`,
        isLightGray && tw`text-LAA-font-light`,
        isRed && tw`text-LAA-red`,
        isGreen && tw`text-LAA-primary`,
    ]
);
const IconWrapper = styled.span(({ isRed, isGreen, isGray, large, Before }) => [
    tw`text-2xl relative`,
    isGreen && tw`text-LAA-primary`,
    isRed && tw`text-LAA-red`,
    isGray && tw`opacity-50`,
    large && tw`text-3xl`,
    Before,
]);

export const EventStartEnd = props => (
    <ShadowBox>
        <ItemWrapper>
            <IconWrapper
                isGreen
                Before={tw`before:(content-['Start Date'] absolute whitespace-nowrap -top-12 left-1/2 -translate-x-1/2 text-xs font-semibold)`}
            >
                <FaCalendarAlt />
            </IconWrapper>
            <p>
                <FontWeight boldBlock>
                    {props.data?.startDate && formatInTimeZone(new Date(props.data.startDate), TIMEZONE, "iiii")}
                </FontWeight>
                <FontWeight semibold>
                    {props.data?.startDate && formatInTimeZone(new Date(props.data.startDate), TIMEZONE, "do MMM yyyy")}
                </FontWeight>
            </p>
        </ItemWrapper>

        <IconWrapper isGray large>
            <GrNext />
        </IconWrapper>

        <ItemWrapper>
            <IconWrapper
                isRed
                Before={tw`before:(content-['End Date'] absolute whitespace-nowrap -top-12 left-1/2 -translate-x-1/2 text-xs font-semibold)`}
            >
                <FaCalendarAlt />
            </IconWrapper>
            <p>
                <FontWeight boldBlock>
                    {props.data?.endDate && formatInTimeZone(new Date(props.data.endDate), TIMEZONE, "iiii")}
                </FontWeight>
                <FontWeight semibold>
                    {props.data?.endDate && formatInTimeZone(new Date(props.data.endDate), TIMEZONE, "do MMM yyyy")}
                </FontWeight>
            </p>
        </ItemWrapper>
    </ShadowBox>
);
